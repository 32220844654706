import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { redirect, useNavigate, useParams } from "react-router-dom";
import { detailFormation } from "../../redux/actions/formationActions";
import HeaderWeb from "../../layouts/HeaderWeb";
import FooterWeb from "../../layouts/FooterWeb";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import { baseURLFile } from "../../constants";
import { createOrderOnline } from "../../redux/actions/orderActions";
import { toast } from "react-toastify";

function CheckoutScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");

  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");

  const [codePostal, setCodePostal] = useState("");
  const [codePostalError, setCodePostalError] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [paymentType, setPaymentType] = useState("Direct Bank Transfer");
  const [paymentTypeError, setPaymentTypeError] = useState("");

  const formationDetail = useSelector((state) => state.detailFormation);
  const { loadingFormation, errorFormation, successFormation, formation } =
    formationDetail;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderCreateOnline = useSelector((state) => state.createOrderOnline);
  const { loadingOrderAdd, errorOrderAdd, successOrderAdd, orderInfo } =
    orderCreateOnline;

  useEffect(() => {
    if (userInfo) {
      dispatch(detailFormation(id));
      console.log(userInfo?.email);
    } else {
      navigate("/");
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (successOrderAdd) {
      if (orderInfo) {
        if (paymentType === "Direct Bank Transfer") {
          navigate("/order-received/" + orderInfo.id);
        } else {
        }
      } else {
      }
    }
  }, [successOrderAdd]);

  return (
    <div className="bg-white text-black min-h-screen ">
      <HeaderWeb />
      {/*  */}
      <div className="my-20 container mx-auto">
        <div className="flex md:flex-row flex-col-reverse">
          <div className="md:w-1/2 w-full px-2 py-1">
            <div className="font-bold text-xl">1. Contact Information</div>
            <div className="mx-1 my-2 pl-3">
              <div className="text-xs ">
                We'll use this email to send you details and updates about your
                order.
              </div>
              <div className="my-2">
                <label className="text-sm text-[#12121280]">
                  Email <strong className="text-danger">*</strong>
                </label>
                <input
                  className="px-2 py-2 border border-[#12121280] outline-none rounded-md w-full bg-gray text-sm"
                  type="email"
                  disabled
                  value={userInfo?.email}
                />
              </div>
            </div>
            <div className="font-bold text-xl">2. Billing address</div>
            <div className="mx-1 my-2 pl-3">
              <div className="text-xs ">
                Enter the billing address that matches your payment method.
              </div>
              <div className="my-2">
                <div className="flex md:flex-row flex-col">
                  <div className="md:w-1/2 w-full px-1 py-1">
                    <label className="text-sm text-[#12121280]">
                      First Name <strong className="text-danger">*</strong>
                    </label>
                    <input
                      className="px-2 py-2 border  border-[#12121280] outline-none rounded-md w-full text-sm"
                      type="text"
                      value={firstName}
                      onChange={(v) => setFirstName(v.target.value)}
                    />
                    <div className="text-xs text-danger">
                      {firstNameError ? firstNameError : ""}
                    </div>
                  </div>
                  <div className="md:w-1/2 w-full px-1 py-1">
                    <label className="text-sm text-[#12121280]">
                      Last Name
                    </label>
                    <input
                      className="px-2 py-2 border border-[#12121280] outline-none rounded-md w-full text-sm"
                      type="text"
                      value={lastName}
                      onChange={(v) => setLastName(v.target.value)}
                    />
                    <div className="text-xs text-danger">
                      {lastNameError ? lastNameError : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="flex md:flex-row flex-col">
                  <div className=" w-full px-1 py-1">
                    <label className="text-sm text-[#12121280]">
                      Address <strong className="text-danger">*</strong>
                    </label>
                    <input
                      className="px-2 py-2 border border-[#12121280] outline-none rounded-md w-full text-sm"
                      type="text"
                      value={address}
                      onChange={(v) => setAddress(v.target.value)}
                    />
                    <div className="text-xs text-danger">
                      {addressError ? addressError : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="flex md:flex-row flex-col">
                  <div className=" w-full px-1 py-1">
                    <label className="text-sm text-[#12121280]">
                      Country <strong className="text-danger">*</strong>
                    </label>
                    <input
                      className="px-2 py-2 border border-[#12121280] outline-none rounded-md w-full text-sm"
                      type="text"
                      value={country}
                      onChange={(v) => setCountry(v.target.value)}
                    />
                    <div className="text-xs text-danger">
                      {countryError ? countryError : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="flex md:flex-row flex-col">
                  <div className=" w-full px-1 py-1">
                    <label className="text-sm text-[#12121280]">
                      City <strong className="text-danger">*</strong>
                    </label>
                    <input
                      className="px-2 py-2 border border-[#12121280] outline-none rounded-md w-full text-sm"
                      type="text"
                      value={city}
                      onChange={(v) => setCity(v.target.value)}
                    />
                    <div className="text-xs text-danger">
                      {cityError ? cityError : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="flex md:flex-row flex-col">
                  <div className="md:w-1/2 w-full px-1 py-1">
                    <label className="text-sm text-[#12121280]">
                      Postcode <strong className="text-danger">*</strong>
                    </label>
                    <input
                      className="px-2 py-2 border border-[#12121280] outline-none rounded-md w-full text-sm"
                      type="text"
                      value={codePostal}
                      onChange={(v) => setCodePostal(v.target.value)}
                    />
                    <div className="text-xs text-danger">
                      {codePostalError ? codePostalError : ""}
                    </div>
                  </div>
                  <div className="md:w-1/2 w-full px-1 py-1">
                    <label className="text-sm text-[#12121280]">
                      Phone <strong className="text-danger">*</strong>
                    </label>
                    <input
                      className="px-2 py-2 border border-[#12121280] outline-none rounded-md w-full text-sm"
                      type="text"
                      value={phone}
                      onChange={(v) => setPhone(v.target.value)}
                    />
                    <div className="text-xs text-danger">
                      {phoneError ? phoneError : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="font-bold text-xl">3. Payment options</div>
            <div className="mx-1 my-2 pl-3">
              <div className="my-2">
                <div
                  onClick={() => setPaymentType("Direct Bank Transfer")}
                  className={`cursor-pointer ${
                    paymentType === "Direct Bank Transfer"
                      ? "border-black border-t-2 border-l-2 border-r-2 border-b-2 "
                      : "border-[#12121280] border-t border-l border-r "
                  }  px-2 py-3 `}
                >
                  <div
                    className={`${
                      paymentType === "Direct Bank Transfer" ? "font-bold" : ""
                    } flex flex-row `}
                  >
                    <input
                      type="radio"
                      checked={paymentType === "Direct Bank Transfer"}
                      onChange={(v) => setPaymentType("Direct Bank Transfer")}
                    />
                    <div className="ml-2 mr-1 cursor-pointer">
                      Direct Bank Transfer
                    </div>
                  </div>
                  <div className="text-xs my-1 mx-3">
                    Make your payment directly into our bank account. Please use
                    your Order ID as the payment reference. Your order will not
                    be shipped until the funds have cleared in our account.
                  </div>
                </div>
                <div
                  onClick={() => setPaymentType("Credit Card")}
                  className={`cursor-pointer ${
                    paymentType === "Credit Card"
                      ? "border-black  border-2"
                      : "border-[#12121280] border-b  border-l border-r "
                  } px-2 py-3`}
                >
                  <div
                    className={`${
                      paymentType === "Credit Card" ? "font-bold" : ""
                    } flex flex-row `}
                  >
                    <input
                      type="radio"
                      checked={paymentType === "Credit Card"}
                      onChange={(v) => setPaymentType("Credit Card")}
                    />
                    <div className="ml-2 mr-1 cursor-pointer">
                      Credit / Debit Card
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 mb-2 text-sm">
              By proceeding with your purchase you agree to our Terms and
              Conditions and Privacy Policy
            </div>
            <div className="my-7 flex flex-row justify-between items-center">
              <div className="w-1/2 text-sm">
                <a
                  href={"/card-pay/" + id}
                  className=" flex flex-row items-center w-max"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                    />
                  </svg>

                  <div className="mx-1 ">Return to Basket</div>
                </a>
              </div>
              <div className="w-1/2 flex flex-row items-center justify-end">
                <button
                  onClick={async () => {
                    var check = true;
                    setFirstNameError("");
                    setLastNameError("");
                    setAddressError("");
                    setCountryError("");
                    setCityError("");
                    setPhoneError("");
                    setCodePostalError("");

                    if (firstName === "") {
                      setFirstNameError("This field is required");
                      check = false;
                    }
                    if (address === "") {
                      setAddressError("This field is required");
                      check = false;
                    }
                    if (country === "") {
                      setCountryError("This field is required");
                      check = false;
                    }
                    if (city === "") {
                      setCityError("This field is required");
                      check = false;
                    }
                    if (phone === "") {
                      setPhoneError("This field is required");
                      check = false;
                    }
                    if (codePostal === "") {
                      setCodePostalError("This field is required");
                      check = false;
                    }
                    if (check) {
                      await dispatch(
                        createOrderOnline(
                          {
                            order_total: formation.is_promotion
                              ? parseFloat(
                                  formation?.price_formation -
                                    (formation?.price_formation *
                                      formation.promotion_formation) /
                                      100
                                ).toFixed(2)
                              : parseFloat(formation?.price_formation).toFixed(
                                  2
                                ),
                            payment_method: paymentType,
                            user_email: userInfo?.email,
                            trans_payment: "",
                            first_name: firstName ?? "",
                            last_name: lastName ?? "",
                            address: address ?? "",
                            country: country ?? "",
                            city: city ?? "",
                            phone: phone ?? "",
                            code_postal: codePostal ?? "",
                            formation_image:
                              baseURLFile + formation.cover_formation,
                          },
                          id
                        )
                      );
                    } else {
                      toast.error("Some fields are required please check");
                    }
                  }}
                  className="text-white bg-primary py-2 px-10 rounded "
                >
                  Place Order
                </button>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 w-full px-5 py-1">
            <div className="w-full bg-danger text-white font-bold py-2 px-2 rounded-full text-center text-xl">
              Order summary
            </div>
            {loadingFormation ? (
              <Loader />
            ) : errorFormation ? (
              <Alert type="error" message={errorFormation} />
            ) : formation ? (
              <div className="my-3 px-5  ">
                <table className="w-full ">
                  <tbody>
                    <td className="max-w-15">
                      <img
                        className="size-10 mx-2"
                        alt="formation"
                        src={baseURLFile + formation.cover_formation}
                      />
                    </td>
                    <td>
                      <div className="text-sm">{formation.title_formation}</div>
                      <div className="text-sm">
                        1 *{" "}
                        {formation.is_promotion
                          ? parseFloat(
                              formation?.price_formation -
                                (formation?.price_formation *
                                  formation.promotion_formation) /
                                  100
                            ).toFixed(2)
                          : parseFloat(formation?.price_formation).toFixed(2)}
                        $
                      </div>
                    </td>
                    <td>
                      <div className="font-bold text-sm text-right">
                        {formation.is_promotion
                          ? parseFloat(
                              formation?.price_formation -
                                (formation?.price_formation *
                                  formation.promotion_formation) /
                                  100
                            ).toFixed(2)
                          : parseFloat(formation?.price_formation).toFixed(2)}
                        $
                      </div>
                    </td>
                  </tbody>
                </table>
                <div className="flex flex-row justify-between items-center py-5 border-b border-[#eee] ">
                  <div>Subtotal</div>
                  <div>
                    {formation.is_promotion
                      ? parseFloat(
                          formation?.price_formation -
                            (formation?.price_formation *
                              formation.promotion_formation) /
                              100
                        ).toFixed(2)
                      : parseFloat(formation?.price_formation).toFixed(2)}
                    $
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center py-5  font-bold">
                  <div>Total</div>
                  <div>
                    {formation.is_promotion
                      ? parseFloat(
                          formation?.price_formation -
                            (formation?.price_formation *
                              formation.promotion_formation) /
                              100
                        ).toFixed(2)
                      : parseFloat(formation?.price_formation).toFixed(2)}
                    $
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default CheckoutScreen;
