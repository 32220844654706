import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function SliderHome() {
  return (
    <div>
      <div className="flex md:flex-row flex-col ">
        <div className="md:w-2/3 w-full min-h-[500px]">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation
            autoplay={{
              delay: 1000,
            }}
            pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
          >
            <SwiperSlide>
              <div
                className=" h-[500px] flex items-center bg-cover bg-no-repeat"
                style={{
                  backgroundImage:
                    'url("https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRlN0dWR5aW5nLmpwZyZjYWNoZU1hcmtlcj0xNzEzMTE3MzMxLTcwODE4JnRva2VuPTNkNjYzMzllYzA0MWZkZTk.q.jpg")',
                }}
              >
                <div className="w-full h-full bg-black bg-opacity-70">
                  <div className="md:w-1/2 w-2/3 mx-auto flex flex-col h-full justify-center items-center text-white">
                    <div className="text-3xl font-bold mb-4 mt-1 text-center">
                      Online Learning Offers Flexibility
                    </div>
                    <div className="text-center text-sm">
                      To overcome barriers like full or part-time employment,
                      enabling you to unlock your potential and pursue a
                      respected, industry-recognised qualification through
                      distance learning with the Hijama Online Academy,
                      regardless of your circumstances or location.
                    </div>
                    <a
                      href="/course-page/1"
                      className="my-3 rounded-full border border-white text-white px-6 py-3 "
                    >
                      Click Here
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className=" h-[500px] flex items-center bg-cover bg-no-repeat"
                style={{
                  backgroundImage:
                    'url("https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRlN0dWR5aW5nLmpwZyZjYWNoZU1hcmtlcj0xNzEzMTE3MzMxLTcwODE4JnRva2VuPTNkNjYzMzllYzA0MWZkZTk.q.jpg")',
                }}
              >
                <div className="w-full h-full bg-black bg-opacity-70">
                  <div className="md:w-1/2 w-2/3 mx-auto flex flex-col h-full justify-center items-center text-white">
                    <div className="text-3xl font-bold mb-4 mt-1 text-center">
                      Online Learning Offers Flexibility
                    </div>
                    <div className="text-center text-sm">
                      To overcome barriers like full or part-time employment,
                      enabling you to unlock your potential and pursue a
                      respected, industry-recognised qualification through
                      distance learning with the Hijama Online Academy,
                      regardless of your circumstances or location.
                    </div>
                    <a
                      href="/course-page/1"
                      className="my-3 rounded-full border border-white text-white px-6 py-3 "
                    >
                      Click Here
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className=" h-[500px] flex items-center bg-cover bg-no-repeat"
                style={{
                  backgroundImage:
                    'url("https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRlN0dWR5aW5nLmpwZyZjYWNoZU1hcmtlcj0xNzEzMTE3MzMxLTcwODE4JnRva2VuPTNkNjYzMzllYzA0MWZkZTk.q.jpg")',
                }}
              >
                <div className="w-full h-full bg-black bg-opacity-70">
                  <div className="md:w-1/2 w-2/3 mx-auto flex flex-col h-full justify-center items-center text-white">
                    <div className="text-3xl font-bold mb-4 mt-1 text-center">
                      Online Learning Offers Flexibility
                    </div>
                    <div className="text-center text-sm">
                      To overcome barriers like full or part-time employment,
                      enabling you to unlock your potential and pursue a
                      respected, industry-recognised qualification through
                      distance learning with the Hijama Online Academy,
                      regardless of your circumstances or location.
                    </div>
                    <a
                      href="/course-page/1"
                      className="my-3 rounded-full border border-white text-white px-6 py-3 "
                    >
                      Click Here
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="md:w-1/3 w-full  min-h-[500px] bg-graydark flex flex-col items-center justify-center">
          <div className="text-xl text-center  w-2/3 mx-auto text-white font-bold">
            Subscribe now to receive your free Hijama e-book
          </div>
          <div className="my-1  w-2/3 mx-auto mt-5">
            <input
              className="bg-white text-black  w-full px-3 py-2 rounded"
              placeholder="Name"
            />
          </div>
          <div className="my-1  w-2/3 mx-auto">
            <input
              className="bg-white text-black  w-full  px-3 py-2 rounded"
              placeholder="Name"
            />
          </div>
          <div className="my-1  w-2/3 mx-auto bg-danger text-white text-center rounded-full  px-3 py-2">
            Submit
          </div>
        </div>
      </div>
    </div>
  );
}

export default SliderHome;
