import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import HeaderWeb from "../../layouts/HeaderWeb";
import FooterWeb from "../../layouts/FooterWeb";
import { getDetailOrder } from "../../redux/actions/orderActions";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";

function OrderReceivedScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderDetail = useSelector((state) => state.detailOrder);
  const { loadingOrderInfo, errorOrderInfo, successOrderInfo, orderInfo } =
    orderDetail;

  useEffect(() => {
    if (userInfo) {
      dispatch(getDetailOrder(id));
    } else {
      navigate("/");
    }
  }, [dispatch, id]);

  return (
    <div className="bg-white text-black min-h-screen ">
      <HeaderWeb />
      {/*  */}
      <div className="my-20 container mx-auto">
        {loadingOrderInfo ? (
          <Loader />
        ) : errorOrderInfo ? (
          <Alert message={errorOrderInfo} />
        ) : orderInfo ? (
          <div>
            <div className="font-bold text-xl">Order received</div>
            <div className="my-2">Thank you. Your order has been received.</div>
            <div className="flex md:flex-row flex-col md:items-center my-5">
              <div className="flex md:flex-row flex-col md:items-center">
                <div className="mx-1 md:border-r px-3">
                  <div className="text-sm  my-1">Order number:</div>
                  <div className="text-sm font-bold">{orderInfo.id}</div>
                </div>
                <div className="mx-1 md:border-r px-3">
                  <div className="text-sm  my-1">Date:</div>
                  <div className="text-sm font-bold">
                    {orderInfo.created_at}
                  </div>
                </div>
              </div>
              <div className="flex md:flex-row flex-col md:items-center">
                <div className="mx-1 md:border-r px-3">
                  <div className="text-sm  my-1">email:</div>
                  <div className="text-sm font-bold">
                    {orderInfo.user_email}
                  </div>
                </div>
                <div className="mx-1 md:border-r px-3">
                  <div className="text-sm my-1">Total:</div>
                  <div className="text-sm font-bold">
                    {parseFloat(orderInfo.order_total).toFixed(2)}$
                  </div>
                </div>
              </div>
              <div className="flex md:flex-row flex-col md:items-center">
                <div className="mx-1  px-3">
                  <div className="text-sm  my-1">Payment method:</div>
                  <div className="text-sm font-bold">
                    {orderInfo.payment_method}
                  </div>
                </div>
              </div>
            </div>
            <div className="font-bold text-xl mt-5">Our bank details</div>
            <div className="my-2 font-bold">Hijama Online Academy Ltd:</div>
            <div className="flex md:flex-row flex-col md:items-center my-5">
              <div className="flex md:flex-row flex-col md:items-center">
                <div className="mx-1 md:border-r px-3">
                  <div className="text-sm  my-1">Bank:</div>
                  <div className="text-sm font-bold">Starling Bank</div>
                </div>
                <div className="mx-1 md:border-r px-3">
                  <div className="text-sm  my-1">Account number:</div>
                  <div className="text-sm font-bold">58395503</div>
                </div>
              </div>
              <div className="flex md:flex-row flex-col md:items-center">
                <div className="mx-1 md:border-r px-3">
                  <div className="text-sm  my-1">Sort code:</div>
                  <div className="text-sm font-bold">608371</div>
                </div>
                <div className="mx-1 md:border-r px-3">
                  <div className="text-sm my-1">IBAN:</div>
                  <div className="text-sm font-bold">
                    GB63SRLG60837158395503
                  </div>
                </div>
              </div>
              <div className="flex md:flex-row flex-col md:items-center">
                <div className="mx-1  px-3">
                  <div className="text-sm  my-1">BIC</div>
                  <div className="text-sm font-bold">SRLGGB2L</div>
                </div>
              </div>
            </div>
            <div className="font-bold text-xl mt-5">Order details</div>
          </div>
        ) : null}
      </div>

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default OrderReceivedScreen;
